import { LocaleMessageDictionary, VueMessageType, I18n } from "vue-i18n";
import { DateTimeFormats } from "@intlify/core-base";
import { Language } from "element-plus/lib/locale";
import { useLoader } from "@/shared/composables/use-loader/use-loader";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import "dayjs/locale/en";
import { ref } from "vue";

export enum Locales {
  Ru = "ru",
  En = "en",
}
export const localeStorageKey = "locale";
const defaultLocale = Locales.Ru;
const locale = ref((localStorage.getItem(localeStorageKey) as Locales) || defaultLocale);

export function useLocales() {
  const getLocale = () => {
    return locale.value;
  };

  const setLocale = async (i18n: I18n<unknown, DateTimeFormats, unknown, false>, newLocale: Locales) => {
    const { locale: i18nLocale, t } = i18n.global;
    await loadLocaleMessages(i18n, newLocale);
    i18nLocale.value = newLocale;
    document.querySelector("html")?.setAttribute("lang", newLocale);
    document.title = t("COMMON.APP_TITLE");
    localStorage.setItem(localeStorageKey, newLocale);
    locale.value = newLocale;
    dayjs.locale(newLocale);
  };

  const { isLoading: localeLoadingState, actionWithLoader: setLocaleWL } = useLoader(setLocale);

  const loadLocaleMessages = async (i18n: I18n<unknown, DateTimeFormats, unknown, false>, newLocale: Locales) => {
    const { setLocaleMessage } = i18n.global;
    const messages: { default: LocaleMessageDictionary<VueMessageType> } = await import(`@/locales/${newLocale}.json`);
    const elMessages: { default: Language } = await import(`element-plus-lang/${newLocale}.js`);

    setLocaleMessage(newLocale, { ...messages.default, el: elMessages.default });
  };

  return { getLocale, setLocale: setLocaleWL, localeLoadingState, loadLocaleMessages };
}
