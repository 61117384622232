import { showMessageBox } from "@/shared/ui/message/message";
import ErrorMessageComponent from "@/shared/ui/error-message/error-message.vue";
import { h } from "vue";
import { MessageBoxData } from "element-plus";

export interface ErrorMessage {
  code: string;
  msg: string;
  err: string;
}

export const getErrorCode = (error: Error): ErrorMessage | string => {
  let result: string | ErrorMessage = "";
  try {
    result = JSON.parse(error.message) as ErrorMessage;
  } catch {
    result = error.message;
  }
  return result;
};

export const showErrorDialog = (e: Error): Promise<MessageBoxData> => {
  const error = getErrorCode(e);
  if (typeof error === "string") {
    // eslint-disable-next-line no-console
    console.warn(`Ошибка: ${error}`);
    const messageNode = h(ErrorMessageComponent, { message: decodeURI(error) });
    return showMessageBox(messageNode, { type: "error" });
  } else {
    // eslint-disable-next-line no-console
    console.warn(`Код ошибки: ${error.code},\n Сообщение: ${decodeURI(error.msg)},\n Ошибка ${error.err}`);
    const messageNode = h(ErrorMessageComponent, {
      errorCode: error.code,
      message: decodeURI(error.msg),
      error: error.err,
    });
    return showMessageBox(messageNode, { type: "error" });
  }
};
