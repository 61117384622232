<template>
  <slot v-if="isAuth" />
  <div v-else class="tn_auth_page">
    <AuthError v-if="isError" />
    <AuthAccessDenied v-if="!isError && isAccessDenied" />
    <Spinner v-if="!isError && !isAccessDenied && !isAuth" class="tn_auth_page__loader" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useAuthState } from "@/store/modules/auth";
import AuthError from "@/shared/ui/auth/components/auth-error/auth-error.vue";
import AuthAccessDenied from "@/shared/ui/auth/components/auth-access-denied/auth-access-denied.vue";
import Spinner from "@/shared/ui/loader/components/spinner.vue";
import { useTNLifeBridge } from "@/shared/composables/use-tnlife-bridge/use-tnlife-bridge";
import { LifeMiniAppMessageType } from "@/shared/composables/use-tnlife-bridge/use-tnlife-bridge.types";
import { Employee } from "@finapp/proto/pkg-ts/common/common";

const { isApp, checkSupportMethod, showSplashScreen, hideSplashScreen } = useTNLifeBridge();

export default defineComponent({
  name: "Auth",
  components: { Spinner, AuthAccessDenied, AuthError },
  setup() {
    const { state: authState } = useAuthState();
    const user = computed<Employee>(() => authState.user);
    const error = ref<boolean>(false);

    const isError = computed(() => authState.error);
    const isAuth = computed(() => user.value.portalCode && user.value.profileId !== "default");
    const isAccessDenied = computed(() => user.value.profileId !== "default" && !user.value.portalCode);

    onMounted(async () => {
      if (isApp() && (await checkSupportMethod(LifeMiniAppMessageType.LifeMiniAppShowSplash))) {
        void showSplashScreen();
      }
    });

    watch([user, isAuth, isError], async () => {
      if (isApp() && (await checkSupportMethod(LifeMiniAppMessageType.LifeMiniAppShowSplash))) {
        void hideSplashScreen();
      }
    });

    return {
      isAuth,
      isError,
      isAccessDenied,
      error,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_colors.scss";

.tn_auth_page {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;

  &__loader {
    width: 80px;
    height: 80px;
  }
}
</style>
