import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-11d1d219"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "tn_auth_page"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AuthError = _resolveComponent("AuthError")!
  const _component_AuthAccessDenied = _resolveComponent("AuthAccessDenied")!
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_ctx.isAuth)
    ? _renderSlot(_ctx.$slots, "default", { key: 0 }, undefined, true)
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.isError)
          ? (_openBlock(), _createBlock(_component_AuthError, { key: 0 }))
          : _createCommentVNode("", true),
        (!_ctx.isError && _ctx.isAccessDenied)
          ? (_openBlock(), _createBlock(_component_AuthAccessDenied, { key: 1 }))
          : _createCommentVNode("", true),
        (!_ctx.isError && !_ctx.isAccessDenied && !_ctx.isAuth)
          ? (_openBlock(), _createBlock(_component_Spinner, {
              key: 2,
              class: "tn_auth_page__loader"
            }))
          : _createCommentVNode("", true)
      ]))
}