import { RouteType } from "@/router/route.type";
import { RouteRecordRaw } from "vue-router";

export const officeManagerRequestsRoutes: RouteRecordRaw = {
  path: "/office-manager-requests",
  name: RouteType.OfficeManagerRequests,
  component: () => import("@/views/dev-page/dev-page.vue"),
  meta: {
    title: "Заявки офис менеджеру",
  },
  children: [
    {
      path: "create",
      name: RouteType.OfficeManagerRequestsCreate,
      component: () => import("@/views/dev-page/dev-page.vue"),
      meta: {
        title: "Заявка офис менеджеру",
      },
    },
  ],
};
