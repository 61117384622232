<template>
  <img class="tn_loader__spinner" :class="{ center }" src="./images/spinner.svg" alt="loading" />
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Spinner",
  props: {
    center: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style scoped>
.tn_loader__spinner {
  animation: rotate 1s linear infinite;
}

.center {
  display: block;
  margin: 0 auto;
  padding: 10px 0;
}

@keyframes rotate {
  from {
    transform: rotate(360deg);
  }
}
</style>
