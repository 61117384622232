import { createI18n } from "vue-i18n";
import { datetimeFormats } from "@/i18n/datetime";
import { pluralRules } from "@/i18n/plural";
import { Locales } from "@/shared/composables/use-locales";

const i18n = createI18n({
  fallbackLocale: Locales.Ru,
  availableLocales: [Locales.Ru, Locales.En],
  legacy: false,
  datetimeFormats,
  pluralRules,
  globalInjection: true,
});

export default i18n;
