import { NavigationGuard } from "vue-router";
import { RouteType } from "@/router/route.type";
import { useAuthActions, useAuthState } from "@/store/modules/auth";
import { useAcl } from "vue-simple-acl/src";
import { showErrorDialog } from "@/shared/helper/error.helper";
import { AclRole } from "@/shared/plugin/acl/acl.type";
import { computed } from "vue";

const accessToAll: AclRole[] = [
  AclRole.User,
  AclRole.ApprovingAgent,
  AclRole.Accountant,
  AclRole.Director,
  AclRole.Admin,
];

const { getAuthUser } = useAuthActions();
const { state: authState } = useAuthState();
const { anyRole } = useAcl();
const user = computed(() => authState.user);

export const aclHook: NavigationGuard = async (to) => {
  if (to.name === RouteType.Error) {
    return true;
  }
  if (!user.value.portalCode) {
    try {
      await getAuthUser();
    } catch (e) {
      await showErrorDialog(e as Error);
      return { name: RouteType.Error };
    }
  }

  const access = to.meta?.access || accessToAll;

  if (anyRole(access)) {
    return true;
  } else {
    return { name: RouteType.Error };
  }
};
