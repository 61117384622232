import { NavigationGuard } from "vue-router";
import { useAuthActions, useAuthState } from "@/store/modules/auth";
import { useTNLifeBridge } from "@/shared/composables/use-tnlife-bridge/use-tnlife-bridge";
import { RouteType } from "@/router/route.type";
import { ResultEventType } from "@/shared/composables/use-tnlife-bridge/use-tnlife-bridge.types";
import { showErrorDialog } from "@/shared/helper/error.helper";
import { computed } from "vue";
import { useRedirectAfterAuth } from "@/router/hooks/redirect.router-hook";

export const authHook: NavigationGuard = async (to, from, next) => {
  const { getRedirectUrlAfterAuth, setRedirectUrlAfterAuth } = useRedirectAfterAuth();
  const { getAuthUrl, getAuthToken, getAuthUser, refreshToken: refreshTokenAction, logOut } = useAuthActions();
  const { state: authState } = useAuthState();
  const { isApp, getLifeMiniAppAuthCode } = useTNLifeBridge();
  const token = computed(() => authState.token);
  const refreshToken = computed(() => authState.refreshToken);
  const expiresAt = computed(() => authState.expiresAt);
  const redirectRoute = getRedirectUrlAfterAuth();
  if (to.name === RouteType.AuthCallback) {
    if (!redirectRoute) {
      setRedirectUrlAfterAuth("/");
    }
    logOut();
    try {
      const code = String(to.query.code);
      await getAuthToken(code);
      await getAuthUser();

      next(true);
      return;
    } catch (e) {
      void showErrorDialog(e as Error);
      next(false);
      return;
    }
  }

  if (refreshToken.value && expiresAt.value && Date.now() >= expiresAt.value) {
    if (!redirectRoute) {
      setRedirectUrlAfterAuth(to.fullPath || "/");
    }
    if (refreshToken.value) {
      try {
        await refreshTokenAction(refreshToken.value);
        await getAuthUser();

        next(true);
        return;
      } catch (e) {
        logOut();
      }
    } else {
      logOut();
    }
  }

  if (!token.value && to.name !== RouteType.AuthCallback) {
    if (!redirectRoute) {
      setRedirectUrlAfterAuth(to.fullPath || "/");
    }
    if (isApp()) {
      const authCodeData = await getLifeMiniAppAuthCode();
      if (authCodeData.type === ResultEventType.LifeMiniAppAuthCodeResult) {
        next({ name: RouteType.AuthCallback, query: { code: authCodeData.data.code } });
        return;
      } else {
        void showErrorDialog(new Error(authCodeData.data.errorData.message));
        next(false);
        return;
      }
    } else {
      try {
        let authUrl = await getAuthUrl();
        if (process.env.NODE_ENV === "development") {
          const developmentAuthUrl = new URL(authUrl);
          developmentAuthUrl.searchParams.set("redirect_uri", `${window.location.origin}/${RouteType.AuthCallback}`);
          authUrl = developmentAuthUrl.toString();
        }
        window.location.href = authUrl;
      } catch (e) {
        void showErrorDialog(e as Error);
      } finally {
        next(false);
      }
    }
  }

  next(true);
};
