export function ruPluralRules(choice: number, choicesLength: number) {
  const choiceABS = Math.abs(choice);
  if (choiceABS === 0) {
    return 0;
  }

  const teen = choiceABS > 10 && choiceABS < 20;
  const endsWithOne = choiceABS % 10 === 1;
  if (!teen && endsWithOne) {
    return 1;
  }
  if (!teen && choiceABS % 10 >= 2 && choiceABS % 10 <= 4) {
    return 2;
  }

  return choicesLength < 4 ? 2 : 3;
}
