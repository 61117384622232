<template>
  <div class="error-message">
    <p v-if="errorCode">Код ошибки: {{ errorCode }}</p>
    <p v-if="message">Сообщение: {{ message }}</p>
    <div v-if="error">
      <ElCollapse accordion>
        <ElCollapseItem title="Подробнее">
          <div class="error-message-text-error">
            {{ error }}
          </div>
        </ElCollapseItem>
      </ElCollapse>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ElCollapse, ElCollapseItem } from "element-plus";

export default defineComponent({
  name: "ErrorMessage",
  components: {
    ElCollapse,
    ElCollapseItem,
  },
  props: {
    errorCode: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    error: {
      type: String,
      default: "",
    },
  },
});
</script>

<style scoped lang="scss">
.error-message {
  font-family: Proxima Nova, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;

  &-text-error {
    font-family: monospace, monospace;
    max-height: 370px;
    overflow-y: auto;
  }

  :deep(.el-collapse) {
    border-top: none;
    border-bottom: none;
  }

  :deep(.el-collapse-item__wrap),
  :deep(.el-collapse-item__header) {
    border-bottom: none;
    font-family: Proxima Nova, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
  }
}
</style>
