import { readonly } from "vue";

export interface AppConfig {
  VUE_APP_YANDEX_METRIKA_ID?: string;
  VUE_APP_TN_ORDER_MINIAPP_URL?: string;
  VUE_APP_TN_KB_MINIAPP_ERD_URL?: string;
  VUE_APP_TN_ERD_WARNING_MESSAGE_PERIOD: number;
}

export const appConfig: AppConfig = readonly({
  VUE_APP_YANDEX_METRIKA_ID: window?.config?.VUE_APP_YANDEX_METRIKA_ID || process.env.VUE_APP_YANDEX_METRIKA_ID,
  VUE_APP_TN_ORDER_MINIAPP_URL:
    window?.config?.VUE_APP_TN_ORDER_MINIAPP_URL || process.env.VUE_APP_TN_ORDER_MINIAPP_URL,
  VUE_APP_TN_KB_MINIAPP_ERD_URL:
    window?.config?.VUE_APP_TN_KB_MINIAPP_ERD_URL || process.env.VUE_APP_TN_KB_MINIAPP_ERD_URL,
  VUE_APP_TN_ERD_WARNING_MESSAGE_PERIOD: Number(
    window?.config?.VUE_APP_TN_ERD_WARNING_MESSAGE_PERIOD || process.env.VUE_APP_TN_ERD_WARNING_MESSAGE_PERIOD,
  ),
});
