import { useAcl } from "vue-simple-acl/src";
import { AclRole, AclRules, SetRule } from "@/shared/plugin/acl/acl.type";
import { Employee } from "@finapp/proto/pkg-ts/common/common";
import { CardOrder } from "@finapp/proto/pkg-ts/card/objects";
import { CardOrderStatus } from "@/store/modules/cards/card-order/card-order.type";

const { role, anyRole } = useAcl();

export function setCardOrderAclRules(setRule: SetRule) {
  setRule<(user: Employee, cardOrder: CardOrder) => void>(
    AclRules.CardOrderReleaseAgreementManager,
    (user, cardOrder) => {
      return (
        anyRole([AclRole.Admin, AclRole.Director, AclRole.ApprovingAgent]) &&
        (cardOrder.chief?.portalCode === user.portalCode || role(AclRole.Admin)) &&
        cardOrder.status === CardOrderStatus.AwaitingApprovalManager
      );
    },
  );

  setRule<(user: Employee, cardOrder: CardOrder) => void>(
    AclRules.CardOrderBlockCardApproveAccounter,
    (user, cardOrder) => {
      return (
        anyRole([AclRole.Admin, AclRole.ApprovingAgent, AclRole.Accountant]) &&
        (cardOrder.accounter.some((accounter) => accounter.portalCode === user.portalCode) || role(AclRole.Admin)) &&
        cardOrder.status === CardOrderStatus.AwaitingForBlocking
      );
    },
  );

  setRule<(user: Employee, cardOrder: CardOrder) => void>(
    AclRules.CardOrderChangeLimitApproveAccounter,
    (user, cardOrder) => {
      return (
        anyRole([AclRole.Admin, AclRole.Accountant]) &&
        (cardOrder.accounter.some((accounter) => accounter.portalCode === user.portalCode) || role(AclRole.Admin)) &&
        (cardOrder.status === CardOrderStatus.Accepted || cardOrder.status === CardOrderStatus.AcceptedWithLimitChange)
      );
    },
  );

  setRule<(user: Employee, cardOrder: CardOrder) => void>(AclRules.CardOrderRevoke, (user, cardOrder) => {
    return (
      anyRole([AclRole.Accountant, AclRole.User, AclRole.Director, AclRole.ApprovingAgent, AclRole.Admin]) &&
      cardOrder.employee &&
      cardOrder.employee.portalCode === user.portalCode &&
      (cardOrder.status === CardOrderStatus.AwaitingApprovalManager ||
        cardOrder.status === CardOrderStatus.AwaitingForBlocking)
    );
  });
}
