import { computed, ComputedRef, reactive } from "vue";
import { LocationQuery, RouteLocationRaw, Router } from "vue-router";
import router from "@/router";
import qs from "qs";

interface HistoryState {
  historyBack: string[];
  historyForward: string[];
  current?: string;
}

interface NavigationHistory {
  addToNavigationHistory: (path: string) => void;
  goBack: () => void;
  goForward: () => void;
  canGoBack: ComputedRef<boolean>;
  canGoForward: ComputedRef<boolean>;
  state: HistoryState;
  backPath: ComputedRef<string | undefined>;
  forwardPath: ComputedRef<string | undefined>;
}

const state = reactive<HistoryState>({
  historyBack: [],
  historyForward: [],
  current: window.location.pathname + window.location.search,
});

const navigationHook = (router: Router) => {
  router.afterEach((to) => {
    addToNavigationHistory(to.fullPath);
  });
};

const addToNavigationHistory = (path: string) => {
  if (path === state.current) return;

  if (path === backPath.value && state.current) {
    state.historyForward = [...state.historyForward, state.current];
    state.historyBack = state.historyBack.slice(0, -1);
    state.current = path;
    return;
  }

  if (path === forwardPath.value && state.current) {
    state.historyBack = [...state.historyBack, state.current];
    state.historyForward = state.historyForward.slice(0, -1);
    state.current = path;
    return;
  }

  if (state.current) {
    state.historyBack = [...state.historyBack, state.current];
    state.historyForward = [];
  }
  state.current = path;
};

const backPath = computed<string | undefined>(() => state.historyBack[state.historyBack.length - 1]);
const forwardPath = computed<string | undefined>(() => state.historyForward[state.historyForward.length - 1]);

const getRoute = (path: string) => {
  const query = qs.parse(path) as LocationQuery;
  const route: RouteLocationRaw = {
    path: path,
    query,
    force: true,
  };
  return route;
};

const goBack = () => {
  if (router && backPath.value) {
    const route = getRoute(backPath.value);
    void router.push(route);
  }
};

const goForward = () => {
  if (router && forwardPath.value) {
    const route = getRoute(forwardPath.value);
    void router.push(route);
  }
};

const canGoBack = computed<boolean>(() => !!state.historyBack.length);
const canGoForward = computed<boolean>(() => !!state.historyForward.length);

export const defineNavigation = (router: Router) => {
  return {
    install: () => {
      navigationHook(router);
    },
  };
};

export const useNavigation = (): NavigationHistory => {
  return {
    addToNavigationHistory,
    goBack,
    goForward,
    canGoBack,
    canGoForward,
    state,
    backPath,
    forwardPath,
  };
};
