import { client, getDateFromTimeStamp } from "@/shared/helper/api.helper";
import {
  SubReportForm,
  SubReportItem,
  SubReportRequest,
  SubReportStatus,
} from "@/store/modules/sub-reports/sub-reports.types";
import { DraftRequest, ListRequest, ListResponse, SubReport } from "@finapp/proto/pkg-ts/subreport/messages";
import { SubReportServiceClient } from "@finapp/proto/pkg-ts/subreport/subreport.client";
import { Currencies } from "@finapp/proto/pkg-ts/common/advance_report_objects";
import { Entity } from "@finapp/proto/pkg-ts/common/common";

export const generateSubReportListQuery = ({ pagination }: ListRequest): Promise<ListResponse> =>
  client(SubReportServiceClient)
    .list({ pagination })
    .response.then((res: ListResponse) => res);

export const mapSubReportToSubReportItem = (list: SubReportRequest[]): SubReportItem[] =>
  list.map(
    (item): SubReportItem => ({
      createdAt: item.createdAt && getDateFromTimeStamp(item.createdAt),
      status: item.status,
      amount: item.subreport.amount,
      id: item.id,
      number: item.number,
      employee: item.subreport.beneficiary,
      comment: item.subreport.comment,
      chief: item.subreport.reviewer,
      entity: item.entity,
    }),
  );

export const createSubReportMap = (form: SubReportForm): SubReport => ({
  beneficiary: form.beneficiary,
  beneficiaryAccountants: [],
  reviewer: form.reviewer,
  amount: form.amount,
  currency: form.currency,
  comment: form.comment,
  remark: "",
});

export const createDraftSubReportMap = (form: Partial<SubReportForm>): DraftRequest => ({
  anySubReport: {
    oneofKind: "newSubreport",
    newSubreport: {
      beneficiary: form.beneficiary,
      beneficiaryAccountants: [],
      reviewer: form.reviewer,
      amount: form.amount || 0,
      currency: form.currency || Currencies.RUB,
      comment: form.comment || "",
      remark: "",
    },
  },
});

export const updateSubReportMap = (form: SubReportForm): SubReport => ({
  beneficiary: form.beneficiary,
  reviewer: form.reviewer,
  amount: form.amount,
  currency: form.currency,
  comment: form.comment,
  remark: "",
  beneficiaryAccountants: [],
});

export const updateDraftSubReportMap = (id: string, form: SubReportForm, remark: string = ""): DraftRequest => ({
  anySubReport: {
    oneofKind: "existingSubreport",
    existingSubreport: {
      id,
      entity: Entity.SUB_REPORT,
      number: "",
      status: SubReportStatus.Draft,
      history: [],
      subreport: {
        beneficiaryAccountants: [],
        beneficiary: form.beneficiary,
        reviewer: form.reviewer,
        amount: form.amount || 0,
        currency: form.currency || Currencies.RUB,
        comment: form.comment || "",
        remark,
      },
    },
  },
});

export const getDefaultSubReport = (): SubReportRequest => ({
  id: "",
  number: "",
  entity: Entity.SUB_REPORT,
  status: SubReportStatus.Draft,
  history: [],
  subreport: {
    beneficiaryAccountants: [],
    amount: 0,
    currency: Currencies.RUB,
    comment: "",
    remark: "",
  },
});
