import { reactive, readonly } from "vue";
import { LocationHistoryState, UseLocationHistoryState } from "@/store/modules/location-history/location-history.types";

const locationHistoryState = reactive<LocationHistoryState>({
  pathList: [],
  previousPath: undefined,
});

const addPathToPathList = (path: string): void => {
  if (path !== locationHistoryState.pathList[locationHistoryState.pathList.length - 1]) {
    locationHistoryState.pathList = [...locationHistoryState.pathList, path];
  }
};

const removePreviousPath = (): void => {
  locationHistoryState.pathList = locationHistoryState.pathList.slice(0, locationHistoryState.pathList.length - 2);
};

const setPreviousPath = (path?: string): void => {
  locationHistoryState.previousPath = path;
};

export const useLocationHistoryState = (): UseLocationHistoryState => {
  return {
    state: readonly(locationHistoryState) as Readonly<LocationHistoryState>,
    addPathToPathList,
    setPreviousPath,
    removePreviousPath,
  };
};
