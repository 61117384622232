import { NavigationGuard } from "vue-router";
import router from "@/router";
import { AuthDataKey } from "@/store/modules/auth";

export const redirectHook: NavigationGuard = (to, from, next) => {
  const { getRedirectUrlAfterAuth, removeRedirectUrlAfterAuth } = useRedirectAfterAuth();
  const redirectRoute = getRedirectUrlAfterAuth();

  if (redirectRoute && to.fullPath !== redirectRoute) {
    const resolvedRedirectRoute = router.resolve(redirectRoute);
    removeRedirectUrlAfterAuth();

    next({
      replace: true,
      path: resolvedRedirectRoute.path,
      query: resolvedRedirectRoute.query,
      hash: resolvedRedirectRoute.hash,
    });
    return;
  }

  next(true);
};

export const useRedirectAfterAuth = () => {
  const setRedirectUrlAfterAuth = (redirectUrl: string) => {
    window.localStorage.setItem(AuthDataKey.RedirectRouterPathAfterAuth, redirectUrl);
  };

  const getRedirectUrlAfterAuth = () => {
    return window.localStorage.getItem(AuthDataKey.RedirectRouterPathAfterAuth);
  };

  const removeRedirectUrlAfterAuth = () => {
    window.localStorage.removeItem(AuthDataKey.RedirectRouterPathAfterAuth);
  };

  return {
    setRedirectUrlAfterAuth,
    getRedirectUrlAfterAuth,
    removeRedirectUrlAfterAuth,
  };
};
