<template>
  <AuthInfo :icon="AuthErrorIcon" title="При загрузке произошла ошибка.">
    <LogOut v-if="!isApp()">
      <BaseButton>Попробовать снова</BaseButton>
    </LogOut>
  </AuthInfo>
</template>

<script lang="ts" setup>
import AuthErrorIcon from "../../images/auth-error-icon.svg";
import BaseButton from "@/shared/ui/buttons/base-button/base-button.vue";
import LogOut from "@/components/layouts/app-header/components/user-area/components/log-out/log-out.vue";
import AuthInfo from "@/shared/ui/auth/components/auth-info/auth-info.vue";
import { useTNLifeBridge } from "@/shared/composables/use-tnlife-bridge/use-tnlife-bridge";
const { isApp } = useTNLifeBridge();
</script>
