import { RouteType } from "@/router/route.type";
import { RouteRecordRaw } from "vue-router";
import { RequestWithCommentsView } from "@/components/request/request-with-comments/request-with-comments.types";

export const subReportsRoutes: RouteRecordRaw = {
  path: "/sub-report",
  name: RouteType.SubReport,
  redirect: {
    name: RouteType.SubReportList,
  },
  component: () => import("@/views/sub-report/sub-report.vue"),
  meta: {
    title: "Заявки на подотчёт",
  },
  children: [
    {
      path: "",
      name: RouteType.SubReportList,
      component: () => import("@/views/sub-report/sub-report-list-page/sub-report-list-page.vue"),
      meta: {
        title: "Заявки на подотчёт",
      },
    },
    {
      path: "create",
      name: RouteType.SubReportCreate,
      component: () => import("@/views/sub-report/sub-report-create/sub-report-create.vue"),
      meta: {
        title: "Заявка на подотчёт",
      },
    },
    {
      path: "edit/:requestId",
      name: RouteType.SubReportEdit,
      component: () => import("@/views/sub-report/sub-report-edit/sub-report-edit.vue"),
      meta: {
        title: "Заявка на подотчёт",
        breadcrumbs: [
          {
            label: "Мои заявки",
            route: { name: RouteType.Requests },
          },
        ],
      },
    },
    {
      path: ":requestId/:action(\\approve|improve|cancellation)?",
      name: RouteType.SubReportView,
      component: () => import("@/views/sub-report/sub-report-view/sub-report-view.vue"),
      meta: {
        title: "Заявка на подотчёт",
        breadcrumbs: [
          {
            label: "Мои заявки",
            route: { name: RouteType.Requests },
          },
        ],
      },
      props: {
        visibleView: RequestWithCommentsView.View,
      },
    },
    {
      path: ":requestId/comments",
      name: RouteType.SubReportComments,
      component: () => import("@/views/sub-report/sub-report-view/sub-report-view.vue"),
      meta: {
        title: "Заявка на подотчёт",
        breadcrumbs: [
          {
            label: "Заявки на подотчёт",
            route: { name: RouteType.SubReportList },
          },
        ],
        excludeFromLocationsHistory: true,
      },
      props: {
        visibleView: RequestWithCommentsView.Comments,
      },
    },
  ],
};
