import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElButton = _resolveComponent("ElButton")!

  return (_openBlock(), _createBlock(_component_ElButton, {
    class: _normalizeClass([_ctx.type]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit(_ctx.EmitEvents.Click)))
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }, 8, ["class"]))
}