import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "tn_logout_btn",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.logOut && _ctx.logOut(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}