import { FormTripLocation } from "@/components/absences/absences-form/sub-form/business-trip/components/form-trip-location/form-trip-location.type";
import { RouteLocationRaw } from "vue-router";
import { Component } from "vue";
import {
  Absence,
  AbsenceExpenses,
  AbsenceLocation,
  AbsenceLocationByCountry,
} from "@finapp/proto/pkg-ts/common/absence_objects";
import { SimpleDictValue } from "@finapp/proto/pkg-ts/common/refbooks";
import { Employee, Entity, RequestStatusMessage } from "@finapp/proto/pkg-ts/common/common";
import {
  AbsenceDateCrossesRequest,
  AbsenceDateCrossesResponse,
  AbsenceVacationDaysResponse,
  AvailableVacationDaysResponse,
  ListAbsencesRequest,
  ListAbsencesResponse,
  ListUnattachedAbsencesRequest,
  SuggestApprovingManagersResponse,
  SuggestReplacementEmployeesResponse,
  SuggestReplacementManagersResponse,
  UpdateAbsenceInternalFieldsRequest,
} from "@finapp/proto/pkg-ts/absence/messages";
import { Empty } from "@finapp/proto/pkg-ts/google/protobuf/empty";
import { AbsenceExpenseFormItem } from "@/components/absences/absences-form/sub-form/business-trip/components/absences-form-expenses/absences-form-expenses.type";
import { AbsenceFormState } from "@/components/absences/absences-form/composables/use-absences-form";
import { AdvanceReport } from "@finapp/proto/pkg-ts/common/advance_report_objects";

export enum AbsenceStatus {
  Draft = "draft",
  AwaitingApprovalManager = "awaiting_approval_manager",
  ApprovedManager = "approved_manager",
  NeedChange = "need_change",
  Canceled = "canceled",
  Accepted = "accepted",
  RevokeForChanging = "revoke_for_changing",
  ProcessingError1C = "processing_error_1c",
  ProcessingErrorSign = "processing_error_sign",
  AwaitingSignEmployee = "awaiting_sign_employee",
  DigitallySigned = "digitally_signed",
  PhysicallySigned = "physically_signed",
}

export enum AbsenceReason {
  Vacation = "vacation",
  Illness = "illness",
  BusinessTrip = "business_trip",
  Training = "training",
  OwnExpenseVacation = "own_expense_vacation",
  Absenteeism = "absenteeism",
  DayOff = "day_off",
  DistantWork = "distant_work",
}

export interface AbsenceListItem {
  employee?: Employee;
  createdAt?: Date;
  startDate?: Date;
  endDate?: Date;
  id: string;
  reason: string;
  status: AbsenceStatus;
  requestType?: string;
  locations: AbsenceLocation[];
  entity: Entity;
  number: string;
}

export interface AbsenceTypeItem {
  title: string;
  routeConfig: RouteLocationRaw;
  icon?: Component;
  enable: boolean;
  type: AbsenceReason;
}

export interface AbsencesFormExpenses {
  expense: SimpleDictValue;
  amount: number;
  collapse: boolean;
}

export interface AbsenceForm {
  id?: string;
  status: string;
  comment: string;
  absenceReason: AbsenceReason;
  tripPurposeId: number;
  employee: Employee;
  chiefUser?: Employee;
  accounterUsers: Employee[];
  notifyPerson: Employee[];
  replacementEmployee?: Employee;
  startDate?: Date;
  endDate?: Date;
  locations: FormTripLocation[];
  expenses: AbsenceExpenseFormItem[];
  extraDayForExperience: boolean;
  sickListIssued?: boolean;
  sickListClosed?: boolean;
  sickListNumber?: string;
  replacementManager?: Employee;
  selectedApprovingManager: Employee;
}

export interface BusinessTripWithoutAdvanceReport {
  id: string;
  number: string;
  status: string;
  employeeId: string;
  absencePurpose?: SimpleDictValue;
  absenceReason: string;
  comment: string;
  entity: Entity;
  startDate?: Date;
  endDate?: Date;
  locations: AbsenceLocationByCountry[];
  expenses: AbsenceExpenses[];
}

export interface AbsenceRequest extends Absence {
  status: AbsenceStatus;
  advanceReport?: AdvanceReport;
}

export interface AbsenceState {
  absence: AbsenceRequest;
  unattachedBusinessTripsList: BusinessTripWithoutAdvanceReport[];
  availableVacationDays: AvailableVacationDaysResponse;
}

export interface getAbsenceDateCrossesRequest
  extends Omit<AbsenceDateCrossesRequest, "startDate" | "endDate" | "absenceId"> {
  absenceId?: string;
  startDate: Date;
  endDate: Date;
}

export interface AbsenceActions {
  createAbsence(form: AbsenceForm): Promise<RequestStatusMessage>;
  createAbsenceDraft(form: AbsenceFormState): Promise<RequestStatusMessage>;
  updateAbsence(form: AbsenceForm): Promise<RequestStatusMessage>;
  updateAbsenceDraft(form: AbsenceFormState): Promise<RequestStatusMessage>;
  getAbsenceById(requestId: string): Promise<AbsenceRequest>;
  approvingAbsence(requestId: string): Promise<void>;
  improvementAbsence(requestId: string, comment: string): Promise<void>;
  cancellationAbsence(requestId: string, comment: string): Promise<void>;
  getUnattachedBusinessTripsList(input: ListUnattachedAbsencesRequest): Promise<void>;
  revokeAbsence(absenceId: string): Promise<Empty>;
  getAvailableVacationDays(data: {
    userPortalCode: string;
    absenceStartDate?: Date;
    absenceEndDate?: Date;
  }): Promise<AvailableVacationDaysResponse>;
  getAbsenceVacationDays(absenceId: string): Promise<AbsenceVacationDaysResponse>;
  getAbsenceList(request: ListAbsencesRequest): Promise<ListAbsencesResponse>;
  removeAbsenceDraft(requestId: string): Promise<Empty>;
  markAbsenceAsSignedByHand(requestId: string): Promise<Empty>;
  revokeAcceptedAbsence(id: string): Promise<Empty>;
  cancelAcceptedAbsence(id: string, comment: string): Promise<Empty>;
  getSuggestionsReplacementEmployees(portalCode: string): Promise<SuggestReplacementEmployeesResponse>;
  getSuggestionsReplacementManager(portalCode: string): Promise<SuggestReplacementManagersResponse>;
  getSuggestionsApprovingManagers(
    portalCode: string,
    absenceReason?: AbsenceReason,
  ): Promise<SuggestApprovingManagersResponse>;
  getAbsenceIntersectionsDate(request: getAbsenceDateCrossesRequest): Promise<AbsenceDateCrossesResponse>;
  updateAbsenceInternalFields(request: UpdateAbsenceInternalFieldsRequest): Promise<Empty>;
}

export interface UseAbsenceState {
  state: Readonly<AbsenceState>;
  setAbsence(absence?: AbsenceRequest): void;
  clearAbsence(): void;
  setUnattachedBusinessTripsList(list: BusinessTripWithoutAdvanceReport[]): void;
  getAbsenceDefault(): AbsenceRequest;
  setAvailableVacationDays(response: AvailableVacationDaysResponse): void;
}
