export enum LifeMiniAppMessageType {
  LifeMiniAppInit = "LifeMiniAppInit",
  LifeMiniAppAuthCode = "LifeMiniAppAuthCode",
  LifeMiniAppDownloadFile = "LifeMiniAppDownloadFile",
  LifeMiniAppOpenFile = "LifeMiniAppOpenFile",
  LifeMiniAppSaveToGallery = "LifeMiniAppSaveToGallery",
  LifeMiniAppPickFile = "LifeMiniAppPickFile",
  LifeMiniAppPickFiles = "LifeMiniAppPickFiles",
  LifeMiniAppTakePicture = "LifeMiniAppTakePicture",
  LifeMiniAppGetAppLink = "LifeMiniAppGetAppLink",
  LifeMiniAppOpenUrl = "LifeMiniAppOpenUrl",
  LifeMiniAppGetClientVersion = "LifeMiniAppGetClientVersion",
  LifeMiniAppOpenService = "LifeMiniAppOpenService",
  LifeMiniAppSupports = "LifeMiniAppSupports",
  LifeMiniAppShowSplash = "LifeMiniAppShowSplash",
  LifeMiniAppHideSplash = "LifeMiniAppHideSplash",
}

export enum ResultEventType {
  LifeMiniAppInitResult = "LifeMiniAppInitResult",
  LifeMiniAppAuthCodeResult = "LifeMiniAppAuthCodeResult",
  LifeMiniAppAuthCodeFailed = "LifeMiniAppAuthCodeFailed",
  LifeMiniAppDownloadFileResult = "LifeMiniAppDownloadFileResult",
  LifeMiniAppDownloadFileFailed = "LifeMiniAppDownloadFileFailed",
  LifeMiniAppPickFileResult = "LifeMiniAppPickFileResult",
  LifeMiniAppPickFileFailed = "LifeMiniAppPickFileFailed",
  LifeMiniAppPickFilesResult = "LifeMiniAppPickFilesResult",
  LifeMiniAppPickFilesFailed = "LifeMiniAppPickFilesFailed",
  LifeMiniAppTakePictureResult = "LifeMiniAppTakePictureResult",
  LifeMiniAppTakePictureFailed = "LifeMiniAppTakePictureFailed",
  LifeMiniAppOpenFileResult = "LifeMiniAppOpenFileResult",
  LifeMiniAppOpenFileFailed = "LifeMiniAppOpenFileFailed",
  LifeMiniAppSaveToGalleryResult = "LifeMiniAppSaveToGalleryResult",
  LifeMiniAppSaveToGalleryFailed = "LifeMiniAppSaveToGalleryFailed",
  LifeMiniAppGetAppLinkResult = "LifeMiniAppGetAppLinkResult",
  LifeMiniAppGetAppLinkFailed = "LifeMiniAppGetAppLinkFailed",
  LifeMiniAppOpenUrlResult = "LifeMiniAppOpenUrlResult ",
  LifeMiniAppOpenUrlFailed = "LifeMiniAppOpenUrlFailed",
  LifeMiniAppGetClientVersionResult = "LifeMiniAppGetClientVersionResult",
  LifeMiniAppOpenServiceResult = "LifeMiniAppOpenServiceResult",
  LifeMiniAppOpenServiceFailed = "LifeMiniAppOpenServiceFailed",

  LifeMiniAppSupportsResult = "LifeMiniAppSupportsResult",
  LifeMiniAppSupportsFailed = "LifeMiniAppSupportsFailed",
  LifeMiniAppShowSplashResult = "LifeMiniAppShowSplashResult",
  LifeMiniAppHideSplashResult = "LifeMiniAppHideSplashResult",
}

export enum PickFileExtensionMode {
  AllExtensions,
  ImagesOnly,
}

interface ResponseEvent<T> {
  type: ResultEventType;
  data: T;
  requestId: number;
}

interface FailedResultData {
  error_type: string;
  error_data: {
    type: number;
    message: string;
  };
}

export interface FailedResultDataPascalCase {
  errorType: string;
  errorData: {
    type: number;
    message: string;
  };
}

// App init
interface AppInitResultData {
  payload?: string;
  result: boolean;
}

export interface InitEventResult extends ResponseEvent<AppInitResultData> {
  type: ResultEventType.LifeMiniAppInitResult;
}

// Auth code
interface AuthCodeResultData {
  code: string;
}

export interface AuthCodeEventResult extends ResponseEvent<AuthCodeResultData> {
  type: ResultEventType.LifeMiniAppAuthCodeResult;
}

export interface AuthCodeFailedEventResult extends ResponseEvent<FailedResultDataPascalCase> {
  type: ResultEventType.LifeMiniAppAuthCodeFailed;
}

// Download file
export interface DownloadFileData {
  url: string;
  filename: string;
}

export interface DownloadFileResultData {
  result: boolean;
}

export interface DownloadFileEventResult extends ResponseEvent<DownloadFileResultData> {
  type: ResultEventType.LifeMiniAppDownloadFileResult;
}

export interface DownloadFileFailedEventResult extends ResponseEvent<FailedResultData> {
  type: ResultEventType.LifeMiniAppDownloadFileFailed;
}

// Pick File
interface FileData {
  name: string;
  mimeType: string;
  size: number;
  url: string;
}

export interface PickFileResultData {
  file: FileData;
}

export interface PickFilesResultData {
  files: FileData[];
  size: number;
  name: string;
}

export interface PickFileEventResult extends ResponseEvent<PickFileResultData> {
  type: ResultEventType.LifeMiniAppPickFileResult;
}

export interface PickFileFailedEventResult extends ResponseEvent<FailedResultData> {
  type: ResultEventType.LifeMiniAppPickFileFailed;
}

export interface PickFilesEventResult extends ResponseEvent<PickFilesResultData> {
  type: ResultEventType.LifeMiniAppPickFilesResult;
}

export interface PickFilesFailedEventResult extends ResponseEvent<FailedResultDataPascalCase> {
  type: ResultEventType.LifeMiniAppPickFilesFailed;
}

export interface TakePictureEventResult extends ResponseEvent<PickFileResultData> {
  type: ResultEventType.LifeMiniAppTakePictureResult;
}

export interface TakePictureFailedEventResult extends ResponseEvent<FailedResultData> {
  type: ResultEventType.LifeMiniAppTakePictureFailed;
}

// OpenFile
export enum OpenFileType {
  Images,
  Video,
  PDF,
}

export interface OpenFileData {
  url: string;
  mimeType: string;
  file_type: OpenFileType;
}

export interface OpenFileResultData {
  result: boolean;
}

export interface OpenFileEventResult extends ResponseEvent<OpenFileResultData> {
  type: ResultEventType.LifeMiniAppOpenFileResult;
}

export interface OpenFileFailedEventResult extends ResponseEvent<FailedResultData> {
  type: ResultEventType.LifeMiniAppOpenFileFailed;
}

// Save to gallery
export interface SaveToGalleryData {
  url: string;
  mimeType: string;
}

export interface SaveToGalleryResultData {
  result: boolean;
}

export interface SaveToGalleryEventResult extends ResponseEvent<SaveToGalleryResultData> {
  type: ResultEventType.LifeMiniAppSaveToGalleryResult;
}

export interface SavaToGalleryFailedEventResult extends ResponseEvent<FailedResultData> {
  type: ResultEventType.LifeMiniAppSaveToGalleryFailed;
}

// get app link
export interface GetAppLinkData {
  payload: string;
}

export interface GetAppLinkResultDate {
  url: string;
}

export interface GetAppLinkEventResult extends ResponseEvent<GetAppLinkResultDate> {
  type: ResultEventType.LifeMiniAppGetAppLinkResult;
}

// Open external url
export interface OpenExternalUrlData {
  url: string;
  use_external_browser?: boolean;
  show_open_in_browser_button?: boolean;
}

export interface OpenExternalUrlResultData {
  result: boolean;
}

export interface OpenExternalUrlEventResult extends ResponseEvent<OpenExternalUrlResultData> {
  type: ResultEventType.LifeMiniAppOpenUrlResult;
}

export interface OpenExternalUrlFailedEventResult extends ResponseEvent<FailedResultDataPascalCase> {
  type: ResultEventType.LifeMiniAppOpenUrlFailed;
}

// Get app version
export interface GetClientVersionResultData {
  platform: "desktop" | "web" | "ios" | "android";
  version: string;
}

export interface GetClientVersionResult extends ResponseEvent<GetClientVersionResultData> {
  type: ResultEventType.LifeMiniAppOpenUrlResult;
}

// Открыть ссылку из виджета
export interface OpenServiceData {
  url: string;
}

export interface OpenServiceResultData {
  result: boolean;
}

export interface OpenServiceResult extends ResponseEvent<OpenServiceResultData> {
  type: ResultEventType.LifeMiniAppOpenServiceResult;
}

export interface OpenServiceFailedEventResult extends ResponseEvent<FailedResultDataPascalCase> {
  type: ResultEventType.LifeMiniAppOpenServiceFailed;
}

// Проверка доступности метода
export interface CheckSupportMethodData {
  method: LifeMiniAppMessageType;
}
export interface CheckSupportMethodResultData {
  result: boolean;
}

export interface CheckSupportMethodEventResult extends ResponseEvent<CheckSupportMethodResultData> {
  type: ResultEventType.LifeMiniAppSupportsResult;
}

export interface CheckSupportMethodFailedEventResult extends ResponseEvent<FailedResultDataPascalCase> {
  type: ResultEventType.LifeMiniAppSupportsFailed;
}

// Показать splash экран
export interface ShowSplashResultData {
  result: boolean;
}
export interface ShowSplashResult extends ResponseEvent<ShowSplashResultData> {
  type: ResultEventType.LifeMiniAppShowSplashResult;
}
// Скрыть splash экран
export interface HideSplashResultData {
  result: boolean;
}
export interface HideSplashResult extends ResponseEvent<HideSplashResultData> {
  type: ResultEventType.LifeMiniAppHideSplashResult;
}
