import { createAcl, defineAclRules } from "vue-simple-acl/src";
import { useAuthState } from "@/store/modules/auth";
import { computed, ComputedRef } from "vue";
import type { SetRule } from "@/shared/plugin/acl/acl.type";
import { AclPrivileges, AclRole } from "@/shared/plugin/acl/acl.type";
import { setAbsenceAclRules } from "@/shared/plugin/acl/rules/absence.acl.rules";
import { setAdvanceReportAclRules } from "@/shared/plugin/acl/rules/advance-report.acl.rules";
import { setCardOrderAclRules } from "@/shared/plugin/acl/rules/card-order.acl.rules";
import { setSubReportAclRules } from "@/shared/plugin/acl/rules/sub-report.acl.rules";
import { Employee } from "@finapp/proto/pkg-ts/common/common";

const { state: authState } = useAuthState();
const user = computed(() => authState.user);

const rules = () =>
  defineAclRules((setRule: SetRule) => {
    Object.values(AclRole).forEach((role: AclRole) => {
      setRule<(user: Employee) => boolean>(role, (user) => user.roles.includes(role));
    });

    Object.values(AclPrivileges).forEach((privilege: AclPrivileges) => {
      setRule<(user: Employee) => boolean>(privilege, (user) => Boolean(user.privileges[privilege]));
    });

    setAbsenceAclRules(setRule);
    setAdvanceReportAclRules(setRule);
    setCardOrderAclRules(setRule);
    setSubReportAclRules(setRule);
  });

export const simpleAcl = createAcl<ComputedRef<Employee>>({
  user,
  rules,
});
