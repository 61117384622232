import { App, ref } from "vue";
import { addMediaQueryListener, removeMediaQueryListener } from "@/shared/plugin/device-detect/device-detect.helper";

const changeEvent = "change";

export default {
  install: (app: App): void => {
    const mediaMobile = matchMedia("(min-width: 320px) and (max-width: 991px)");
    const mediaLaptop = matchMedia("(min-width: 992px) and (max-width: 1440px)");
    const mediaDesktop = matchMedia("(min-width: 1441px) and (max-width: 1920px)");
    const isMobile = ref(mediaMobile.matches);
    const isLaptop = ref(mediaLaptop.matches);
    const isDesktop = ref(mediaDesktop.matches);
    const setLayout = () => {
      isMobile.value = mediaMobile.matches;
      isLaptop.value = mediaLaptop.matches;
      isDesktop.value = mediaDesktop.matches;
    };
    addMediaQueryListener(mediaMobile, changeEvent, setLayout);
    addMediaQueryListener(mediaLaptop, changeEvent, setLayout);
    addMediaQueryListener(mediaDesktop, changeEvent, setLayout);
    const unmount = app.unmount.bind(app);
    app.unmount = () => {
      removeMediaQueryListener(mediaMobile, changeEvent, setLayout);
      removeMediaQueryListener(mediaLaptop, changeEvent, setLayout);
      removeMediaQueryListener(mediaDesktop, changeEvent, setLayout);
      unmount();
    };
    app.provide("isMobile", isMobile);
    app.provide("isLaptop", isLaptop);
    app.provide("isDesktop", isDesktop);
  },
};
