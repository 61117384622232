import {
  SubReportForm,
  SubReportListResponse,
  SubReportRequest,
  SubReportStatus,
  UseSubReportsActions,
} from "@/store/modules/sub-reports/sub-reports.types";
import {
  createDraftSubReportMap,
  createSubReportMap,
  mapSubReportToSubReportItem,
  updateDraftSubReportMap,
  updateSubReportMap,
} from "@/store/modules/sub-reports/sub-reports.helper";

import { client } from "@/shared/helper/api.helper";
import { ListRequest } from "@finapp/proto/pkg-ts/subreport/messages";
import { ChangeStatusRequest, RequestStatusMessage } from "@finapp/proto/pkg-ts/common/common";
import { SubReportServiceClient } from "@finapp/proto/pkg-ts/subreport/subreport.client";
import { Empty } from "@finapp/proto/pkg-ts/google/protobuf/empty";

const subReportServiceClient = client(SubReportServiceClient);

const getSubReportsList = (request: ListRequest): Promise<SubReportListResponse> => {
  return subReportServiceClient.list(request).response.then((response) => ({
    ...response,
    items: mapSubReportToSubReportItem(response.items as SubReportRequest[]),
  }));
};
const getSubRequestById = async (id: string): Promise<SubReportRequest> => {
  return (await subReportServiceClient.getByID({ id }).response) as SubReportRequest;
};

const createSubReport = (form: SubReportForm) => {
  const request = createSubReportMap(form);
  return subReportServiceClient.submit(request).response;
};

const createSubReportDraft = (form: Partial<SubReportForm>): Promise<RequestStatusMessage> => {
  const request = createDraftSubReportMap(form);
  return subReportServiceClient.draft(request).response;
};

const updateSubReport = (id: string, form: SubReportForm): Promise<RequestStatusMessage> => {
  const subreport = updateSubReportMap(form);
  return subReportServiceClient.resubmit({ id, subreport }).response;
};

const updateDraftSubReport = (id: string, form: SubReportForm, remark?: string): Promise<RequestStatusMessage> => {
  const request = updateDraftSubReportMap(id, form, remark);
  return subReportServiceClient.draft(request).response;
};

const removeSubReport = (id: string): Promise<Empty> => {
  return subReportServiceClient.delete({ id }).response;
};

const changeSubReportStatus = (
  id: string,
  status: SubReportStatus,
  comment?: string,
): Promise<RequestStatusMessage> => {
  const request: ChangeStatusRequest = {
    id,
    status,
    ...(comment ? { comment: { value: comment } } : {}),
  };
  return subReportServiceClient.changeStatus(request).response;
};

export const useSubReportsActions = (): UseSubReportsActions => ({
  getSubReportsList,
  createSubReport,
  createSubReportDraft,
  updateSubReport,
  updateDraftSubReport,
  getSubRequestById,
  changeSubReportStatus,
  removeSubReport,
});
