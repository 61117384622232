import { RouteType } from "@/router/route.type";
import { RouteRecordRaw } from "vue-router";
import { AclRole } from "@/shared/plugin/acl/acl.type";

export const accountantWorkplaceRoutes: RouteRecordRaw = {
  path: "/accountant-workplace",
  name: RouteType.AccountantWorkplace,
  redirect: {
    name: RouteType.AccountantWorkplaceDashboard,
  },
  meta: {
    access: [AclRole.Accountant, AclRole.Admin, AclRole.Support],
  },
  component: () => import("@/views/accountant-workplace/accountant-workplace.vue"),
  children: [
    {
      path: "",
      name: RouteType.AccountantWorkplaceDashboard,
      component: () =>
        import("@/views/accountant-workplace/accountant-workplace-dashboard/accountant-workplace-dashboard.vue"),
      meta: {
        title: "Рабочее место бухгалтера",
      },
    },
    {
      path: "report-for-tax",
      name: RouteType.AccountantWorkplaceReportForTax,
      component: () => import("@/views/accountant-workplace/report-for-tax/report-for-tax.vue"),
      meta: {
        breadcrumbs: [
          {
            label: "Рабочее место бухгалтера",
            route: { name: RouteType.AccountantWorkplace },
          },
        ],
      },
    },
    {
      path: "eds-registry",
      name: RouteType.AccountantWorkplaceEDSRegistry,
      component: () => import("@/views/accountant-workplace/eds-registry/eds-registry.vue"),
      meta: {
        title: "Реестр ЭЦП",
        breadcrumbs: [
          {
            label: "Рабочее место бухгалтера",
            route: { name: RouteType.AccountantWorkplace },
          },
        ],
      },
    },
  ],
};
