<template>
  <component :is="layout">
    <template #view>
      <router-view />
    </template>
  </component>
</template>

<script lang="ts">
import { computed, defineAsyncComponent, defineComponent, inject, ref } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "Layouts",
  setup() {
    const isMobile = inject("isMobile", ref(true));
    const route = useRoute();

    const defaultLayout = computed(() => {
      return isMobile.value
        ? defineAsyncComponent(() => import("@/layouts/default/mobile/mobile-layout.vue"))
        : defineAsyncComponent(() => import("@/layouts/default/desktop/desktop-layout.vue"));
    });

    const layout = computed(() => {
      return route.meta.layout || defaultLayout.value;
    });

    return { layout };
  },
});
</script>
