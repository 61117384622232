import { RouteType } from "@/router/route.type";
import { RouteRecordRaw } from "vue-router";
import { RequestWithCommentsView } from "@/components/request/request-with-comments/request-with-comments.types";

export const absencesRoutes: RouteRecordRaw = {
  path: "/absences",
  name: RouteType.Absences,
  redirect: {
    name: RouteType.AbsencesList,
  },
  component: () => import("@/views/absences/absences.vue"),
  meta: {
    title: "Отсутствия",
  },
  children: [
    {
      path: "",
      name: RouteType.AbsencesList,
      component: () => import("@/views/absences/absences-list/absences-list.vue"),
      meta: {
        title: "Отсутствия",
      },
    },
    {
      path: ":requestId/:action(\\approve|improve|cancellation)?",
      name: RouteType.AbsenceId,
      component: () => import("@/views/absences/absences-view/absences-view.vue"),
      meta: {
        title: "Отсутствие",
        breadcrumbs: [
          {
            label: "Отсутствия",
            route: { name: RouteType.AbsencesList },
          },
        ],
      },
      props: {
        visibleView: RequestWithCommentsView.View,
      },
    },
    {
      path: ":requestId/comments",
      name: RouteType.AbsenceComment,
      component: () => import("@/views/absences/absences-view/absences-view.vue"),
      meta: {
        title: "Отсутствие",
        breadcrumbs: [
          {
            label: "Отсутствия",
            route: { name: RouteType.AbsencesList },
          },
        ],
        excludeFromLocationsHistory: true,
      },
      props: {
        visibleView: RequestWithCommentsView.Comments,
      },
    },
    {
      path: "create",
      name: RouteType.AbsenceRequestCreate,
      component: () => import("@/views/absences/absences-create/absences-create.vue"),
      meta: {
        title: "Заявка на отсутствие",
      },
      props: true,
    },
    {
      path: "edit/:requestId",
      name: RouteType.AbsenceRequestEdit,
      component: () => import("@/views/absences/absences-edit/absences-edit.vue"),
      meta: {
        title: "Заявка на отсутствие",
        breadcrumbs: [
          {
            label: "Отсутствия",
            route: { name: RouteType.AbsencesList },
          },
        ],
      },
    },
  ],
};
