import { RouteType } from "@/router/route.type";
import { RouteRecordRaw } from "vue-router";

export const myRequestsRoutes: RouteRecordRaw = {
  path: "/requests",
  name: RouteType.Requests,
  redirect: {
    name: RouteType.RequestsList,
  },
  component: () => import("@/views/my-request/my-requests.vue"),
  meta: {
    title: "Мои заявки",
  },
  children: [
    {
      path: "",
      name: RouteType.RequestsList,
      component: () => import("@/views/my-request/my-request-list-page/my-request-list-page.vue"),
      meta: {
        title: "Мои заявки",
      },
    },
  ],
};
