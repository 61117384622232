<template>
  <ElButton :class="[type]" @click="$emit(EmitEvents.Click)">
    <slot />
  </ElButton>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { ElButton } from "element-plus";
import { BaseButtonType } from "@/shared/ui/buttons/base-button/base-button.types";

enum EmitEvents {
  Click = "click",
}

export default defineComponent({
  name: "BaseButton",
  components: { ElButton },
  props: {
    type: {
      type: String as PropType<BaseButtonType>,
      default: BaseButtonType.Primary,
    },
  },
  emits: [EmitEvents.Click],
  setup: () => {
    return {
      EmitEvents,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_colors.scss";
@import "~@/assets/scss/_response.scss";
button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 18px 26px;
  width: 100%;
  min-height: 0;
  height: auto;
  border-radius: 10px;
  border-color: transparent;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  &:hover,
  &:active,
  &:focus {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.7;
    filter: grayscale(80);
  }
}
.primary {
  background: $red;
  color: $white;
  &:hover,
  &:active,
  &:focus {
    background: $red;
    border-color: transparent;
    color: $white;
  }
}
.plain {
  background: inherit;
  color: $red;
  border-color: $red;
  &:hover,
  &:active,
  &:focus {
    background: inherit;
    border-color: $red;
    color: $red;
  }
}
.text {
  padding: 1px 6px;
  width: auto;
  align-items: normal;
  background: inherit;
  border: none;
  color: $red;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.49px;

  @include media_mobile {
    font-size: 14px;
    line-height: 18.2px;
  }

  &:hover,
  &:focus,
  &:active {
    background: inherit;
    color: $red;
  }
}
:deep(span) {
  display: flex;
  align-items: flex-start;
}
</style>
