import { DateTimeFormat } from "@intlify/core-base";

export const ruDatetimeFormats: DateTimeFormat = {
  short: {
    year: "numeric",
    month: "short",
    day: "numeric",
  },
  longDay: {
    year: "numeric",
    month: "long",
    day: "numeric",
  },
  long: {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  },
  time: {
    hour: "numeric",
    minute: "numeric",
  },
  timeWithoutTimezone: {
    hour: "numeric",
    minute: "numeric",
    timeZone: "Greenwich",
  },
  shortDateWithoutTimezone: {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    timeZone: "Greenwich",
  },
};
