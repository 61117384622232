import { UseLocationHistoryActions } from "@/store/modules/location-history/location-history.types";
import { useLocationHistoryState } from "@/store/modules/location-history/location-history.state";

export const useLocationHistoryActions = (): UseLocationHistoryActions => {
  const { state, addPathToPathList, setPreviousPath } = useLocationHistoryState();
  const updateLocationsHistoryState = (path: string): void => {
    if (state.pathList[state.pathList.length - 1] !== path) {
      addPathToPathList(path);
      if (state.pathList.length > 1) {
        const pathListLength = state.pathList.length;
        const previousPath = state.pathList[pathListLength - 2];
        setPreviousPath(previousPath);
      } else {
        setPreviousPath();
      }
    }
  };
  return { updateLocationsHistoryState };
};
