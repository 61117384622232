import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Layouts = _resolveComponent("Layouts")!
  const _component_Locale = _resolveComponent("Locale")!
  const _component_Auth = _resolveComponent("Auth")!

  return (_openBlock(), _createBlock(_component_Auth, null, {
    default: _withCtx(() => [
      _createVNode(_component_Locale, null, {
        default: _withCtx(() => [
          _createVNode(_component_Layouts)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}