import { Employee } from "@finapp/proto/pkg-ts/common/common";

export interface AuthState {
  user: Employee;
  token: string;
  refreshToken: string;
  expiresAt: number;
  role: string;
  error?: Error;
}

export enum AuthDataKey {
  Token = "authToken",
  RefreshToken = "refreshToken",
  ExpiresAt = "expiresAt",
  RedirectRouterPathAfterAuth = "RedirectRouterPathAfterAuth",
}

export interface UseAuthState {
  state: Readonly<AuthState>;
}

export interface AuthActions {
  getAuthUrl(): Promise<string>;
  getAuthToken(code: string): Promise<void>;
  refreshToken(refreshToken: string): Promise<void>;
  getAuthUser(): Promise<Employee>;
  logOut(): void;
}
