import { DateTimeFormat } from "@intlify/core-base";

export const enDatetimeFormats: DateTimeFormat = {
  short: {
    year: "numeric",
    month: "short",
    day: "numeric",
  },
  long: {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  },
  time: {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  },
};
