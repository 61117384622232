const functionType = "function";

export const addMediaQueryListener = (mediaQuery: MediaQueryList, changeEvent: string, cb: VoidFunction) => {
  if (typeof mediaQuery.addEventListener === functionType) {
    mediaQuery.addEventListener(changeEvent, cb);
  } else if (typeof mediaQuery.addListener === functionType) {
    mediaQuery.addListener(cb);
  }
};

export const removeMediaQueryListener = (mediaQuery: MediaQueryList, changeEvent: string, cb: VoidFunction) => {
  if (typeof mediaQuery.removeEventListener === functionType) {
    mediaQuery.removeEventListener(changeEvent, cb);
  } else if (typeof mediaQuery.removeListener === functionType) {
    mediaQuery.removeListener(cb);
  }
};
