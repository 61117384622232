import { client } from "@/shared/helper/api.helper";
import { AuthClient } from "@finapp/proto/pkg-ts/auth.client";
import { AuthActions } from "@/store/modules/auth/";
import { getEmployeeDefault, useAuthState } from "@/store/modules/auth";
import { Employee } from "@finapp/proto/pkg-ts/common/common";

const authClient = client(AuthClient);

export const useAuthActions = (): AuthActions => {
  const { setUser, setAuthData, cleanAuthData, setAuthError } = useAuthState();

  const getAuthUrl = (): Promise<string> => {
    return authClient.getAuthURL({}).response.then((res) => res.url);
  };

  const getAuthToken = (code: string): Promise<void> => {
    return authClient
      .authByCode({ code: code }, { timeout: 60_000 })
      .response.then((authData) => {
        void setAuthData(authData);
      })
      .catch((e) => {
        cleanAuthData();
        setAuthError(e as Error);
      });
  };

  const refreshToken = (refreshToken: string): Promise<void> => {
    return authClient
      .refreshToken({ refreshToken })
      .response.then((authData) => {
        void setAuthData(authData);
      })
      .catch((e) => {
        throw e;
      });
  };

  const logOut = () => {
    cleanAuthData();
    setUser(getEmployeeDefault());
  };

  const getAuthUser = async (): Promise<Employee> => {
    return new Promise((resolve, reject): void => {
      authClient
        .getAuthenticatedUser({})
        .response.then(({ employee }) => {
          if (employee && employee) {
            setUser(employee);
            resolve(employee);
          }
        })
        .catch((e) => {
          setAuthError(e as Error);
          reject(e);
        });
    });
  };

  return {
    getAuthUrl,
    getAuthToken,
    refreshToken,
    getAuthUser,
    logOut,
  };
};
