import { AbsenceReason } from "@/store/modules/absence/absence.types";
import { AclPrivileges, AbsenceSelfApprovalPermissionsList } from "@/shared/plugin/acl/acl.type";

const absenceSelfApprovalPermissionsList: AbsenceSelfApprovalPermissionsList = {
  [AbsenceReason.BusinessTrip]: AclPrivileges.AbsenceSelfApprovalBusinessTrip,
  [AbsenceReason.Vacation]: AclPrivileges.AbsenceSelfApprovalVacation,
  [AbsenceReason.Illness]: AclPrivileges.AbsenceSelfApprovalIllness,
  [AbsenceReason.DayOff]: AclPrivileges.AbsenceSelfApprovalDayOff,
  [AbsenceReason.DistantWork]: AclPrivileges.AbsenceSelfApprovalDayOff,
  [AbsenceReason.Training]: AclPrivileges.AbsenceSelfApprovalTraining,
  [AbsenceReason.OwnExpenseVacation]: AclPrivileges.AbsenceSelfApprovalOwnExpenseVacation,
  [AbsenceReason.Absenteeism]: AclPrivileges.AbsenceSelfApprovalAbsenteeism,
};

export const getAbsenceSelfApprovalPermissionName = (absenceReason: AbsenceReason) => {
  if (absenceSelfApprovalPermissionsList[absenceReason]) {
    return absenceSelfApprovalPermissionsList[absenceReason];
  } else {
    throw new Error("Unsupported AbsenceSelfApprovalPermissions...");
  }
};
