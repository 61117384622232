<template>
  <ElConfigProvider :locale="elLocale">
    <slot v-if="!localeLoadingState" />
  </ElConfigProvider>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { ElConfigProvider } from "element-plus";
import { Language } from "element-plus/lib/locale";
import i18n from "@/i18n";
import { useLocales } from "@/shared/composables/use-locales";

export default defineComponent({
  name: "Locale",
  components: {
    ElConfigProvider,
  },
  setup() {
    const { getLocale, setLocale, localeLoadingState } = useLocales();
    const setupLocale = getLocale();
    void setLocale(i18n, setupLocale);

    const { getLocaleMessage, locale } = i18n.global;
    const elLocale = computed<Language>(() => getLocaleMessage(locale.value).el as Language);

    return {
      elLocale,
      localeLoadingState,
    };
  },
});
</script>
