import { RouteType } from "@/router/route.type";
import { RouteRecordRaw } from "vue-router";
import { RequestWithCommentsView } from "@/components/request/request-with-comments/request-with-comments.types";

export const myCardsRoutes: RouteRecordRaw = {
  path: "/my-cards",
  name: RouteType.MyCards,
  redirect: {
    name: RouteType.MyCardsList,
  },
  component: () => import("@/views/my-cards/my-cards.vue"),
  meta: {
    title: "Мои карты",
  },
  children: [
    {
      path: "",
      name: RouteType.MyCardsList,
      component: () => import("@/views/my-cards/my-cards-list/my-cards-list.vue"),
      meta: {
        title: "Мои карты",
      },
    },
    {
      path: "create",
      name: RouteType.MyCardsCreate,
      component: () => import("@/views/my-cards/my-cards-create/my-cards-create.vue"),
      meta: {
        title: "Заявка на выпуск корпоративной карты",
        breadcrumbs: [
          {
            label: "Мои карты",
            route: { name: RouteType.MyCardsList },
          },
        ],
      },
    },
    {
      path: "fill-details",
      name: RouteType.MyCardsFillDetails,
      component: () => import("@/views/my-cards/my-cards-fill-details/my-cards-fill-details.vue"),
      meta: {
        title: "Заполнение данных карты",
        breadcrumbs: [
          {
            label: "Мои карты",
            route: { name: RouteType.MyCardsList },
          },
        ],
      },
    },
    {
      path: "renew",
      name: RouteType.MyCardsRenew,
      component: () => import("@/views/my-cards/my-cards-renew/my-cards-renew.vue"),
      meta: {
        title: "Заявка на перевыпуск корпоративной карты",
        breadcrumbs: [
          {
            label: "Мои карты",
            route: { name: RouteType.MyCardsList },
          },
        ],
      },
    },
    {
      path: "change-limit",
      name: RouteType.MyCardsChangeLimit,
      component: () => import("@/views/my-cards/my-cards-change-limit/my-cards-change-limit.vue"),
      meta: {
        title: "Заявка на изменение лимита",
        breadcrumbs: [
          {
            label: "Мои карты",
            route: { name: RouteType.MyCardsList },
          },
        ],
      },
    },
    {
      path: "success",
      name: RouteType.MyCardsSuccess,
      component: () => import("@/views/my-cards/my-cards-success/my-cards-success.vue"),
      meta: {
        title: "Заявка успешно создана",
        breadcrumbs: [
          {
            label: "Мои карты",
            route: { name: RouteType.MyCardsList },
          },
        ],
      },
    },
    {
      path: "order/:orderId",
      name: RouteType.MyCardsOrder,
      component: () => import("@/views/my-cards/my-card-order-view/my-card-order-view.vue"),
      meta: {
        breadcrumbs: [
          {
            label: "Мои карты",
            route: { name: RouteType.MyCardsList },
          },
        ],
        title: "",
      },
    },
    {
      path: "order/:orderId/comments",
      name: RouteType.MyCardsOrderComments,
      component: () => import("@/views/my-cards/my-card-order-view/my-card-order-view.vue"),
      meta: {
        breadcrumbs: [
          {
            label: "Мои карты",
            route: { name: RouteType.MyCardsList },
          },
        ],
        title: "",
        excludeFromLocationsHistory: true,
      },
      props: {
        visibleView: RequestWithCommentsView.Comments,
      },
    },
  ],
};
