import { Employee, Entity, RequestStatusMessage } from "@finapp/proto/pkg-ts/common/common";
import { ExistingSubReport, ListRequest, ListResponse, SubReport } from "@finapp/proto/pkg-ts/subreport/messages";
import { Currencies } from "@finapp/proto/pkg-ts/common/advance_report_objects";
import { Empty } from "@finapp/proto/pkg-ts/google/protobuf/empty";

export interface SubReportForm {
  beneficiary: Employee;
  reviewer: Employee;
  currency: Currencies;
  amount: number;
  comment: string;
}

export interface SubReportItem {
  id: string;
  number: string;
  createdAt?: Date;
  status: SubReportStatus;
  amount: number;
  comment: string;
  entity: Entity;
  employee?: Employee;
  chief?: Employee;
}

export enum SubReportStatus {
  Draft = "draft",
  RevokeForChanging = "revoke_for_changing",
  NeedChange = "need_change",
  AwaitingApproval = "awaiting_approval",
  Canceled = "canceled",
  Approved = "approved",
}

export interface SubReportRequest extends ExistingSubReport {
  status: SubReportStatus;
  subreport: SubReport;
}

export interface SubReportListResponse extends Omit<ListResponse, "items"> {
  items: SubReportItem[];
}

export interface UseSubReportsActions {
  createSubReport(form: SubReportForm): Promise<RequestStatusMessage>;
  createSubReportDraft(form: Partial<SubReportForm>): Promise<RequestStatusMessage>;
  updateSubReport(id: string, form: SubReportForm): Promise<RequestStatusMessage>;
  updateDraftSubReport(id: string, form: Partial<SubReportForm>, remark?: string): Promise<RequestStatusMessage>;
  getSubRequestById(subReportId: string): Promise<SubReportRequest>;
  getSubReportsList(request: ListRequest): Promise<SubReportListResponse>;
  changeSubReportStatus(id: string, status: string, comment?: string): Promise<RequestStatusMessage>;
  removeSubReport(id: string): Promise<Empty>;
}
