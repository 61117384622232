import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from './images/spinner.svg'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    class: _normalizeClass(["tn_loader__spinner", { center: _ctx.center }]),
    src: _imports_0,
    alt: "loading"
  }, null, 2))
}