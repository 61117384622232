import { CardOrder } from "@finapp/proto/pkg-ts/card/objects";
import { Empty } from "@finapp/proto/pkg-ts/google/protobuf/empty";

export interface CardOrderRequest extends CardOrder {
  status: CardOrderStatus;
}

export enum OrderTypes {
  Release = "card_release",
  ChangeLimit = "change_limit",
  ReRelease = "card_re_release",
  Blocking = "card_blocking",
}

export enum CardOrderStatus {
  AwaitingApprovalManager = "awaiting_approval_manager",
  AwaitingApprovalAccountant = "awaiting_approval_accountant",
  AwaitingForFillingIn = "awaiting_for_filling_in",
  AcceptedWithLimitChange = "accepted_with_limit_change",
  Accepted = "accepted",
  Rejected = "rejected",
  CardWasReleased = "card_was_released",
  LimitHasBeenChanged = "limit_has_been_changed",
  CardWasReReleased = "card_was_re_released",
  CardWasBlocked = "card_was_blocked",
  AwaitingForBlocking = "awaiting_for_blocking",
  Canceled = "canceled",
  ProcessingError1C = "processing_error_1c",
}

export interface UseCardOrderActions {
  getOrderByID(cardOrderId: string): Promise<CardOrderRequest>;
  approvingManagerCardOrder(orderId: string): Promise<Empty>;
  approvingManagerWithChangeLimitCardOrderRequest(orderID: string, newLimit: number): Promise<Empty>;
  cancellationCardOrder(orderID: string, comment?: string): Promise<Empty>;
  approvingCardBlocking(orderId: string): Promise<Empty>;
  approveChangeCardLimitByAccounter(orderId: string): Promise<Empty>;
  revokeCardRequest(orderId: string, orderType: string): Promise<Empty>;
}
