import { RouteRecordRaw } from "vue-router";
import { RouteType } from "@/router/route.type";
import { AclRole } from "@/shared/plugin/acl/acl.type";

export const reportsRoutes: RouteRecordRaw = {
  path: "/reports",
  name: RouteType.Reports,
  redirect: {
    name: RouteType.ReportsDashboard,
  },
  meta: {
    access: [AclRole.Admin, AclRole.Support, AclRole.ApprovingAgent, AclRole.Director],
  },
  component: () => import("@/views/reports/reports.vue"),
  children: [
    {
      path: "",
      name: RouteType.ReportsDashboard,
      component: () => import("@/views/reports/reports-dashboard/reports-dashboard.vue"),
      meta: {
        title: "Отчёты",
      },
    },
    {
      path: "vacation-days",
      name: RouteType.ReportsVacationDays,
      component: () => import("@/views/reports/vacation-days-report/vacation-days-report.vue"),
      meta: {
        breadcrumbs: [
          {
            label: "Отчёты",
            route: { name: RouteType.Reports },
          },
        ],
      },
    },
  ],
};
