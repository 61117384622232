import TNLifeBridge from "@tnlife/tn-bridge";
import {
  AuthCodeEventResult,
  AuthCodeFailedEventResult,
  CheckSupportMethodData,
  CheckSupportMethodEventResult,
  CheckSupportMethodFailedEventResult,
  DownloadFileData,
  DownloadFileEventResult,
  DownloadFileFailedEventResult,
  GetAppLinkData,
  GetAppLinkEventResult,
  GetClientVersionResult,
  HideSplashResult,
  InitEventResult,
  LifeMiniAppMessageType,
  OpenExternalUrlData,
  OpenExternalUrlFailedEventResult,
  OpenExternalUrlResultData,
  OpenFileData,
  OpenFileEventResult,
  OpenFileFailedEventResult,
  OpenServiceData,
  OpenServiceFailedEventResult,
  OpenServiceResultData,
  PickFileEventResult,
  PickFileExtensionMode,
  PickFileFailedEventResult,
  PickFilesEventResult,
  PickFilesFailedEventResult,
  ResultEventType,
  SaveToGalleryData,
  SaveToGalleryEventResult,
  ShowSplashResult,
  TakePictureEventResult,
  TakePictureFailedEventResult,
} from "@/shared/composables/use-tnlife-bridge/use-tnlife-bridge.types";

export const useTNLifeBridge = () => {
  const isBrowser = () => {
    return !TNLifeBridge.isWebView && !TNLifeBridge.isIFrame;
  };

  const isApp = () => {
    return TNLifeBridge.isWebView || TNLifeBridge.isIFrame;
  };

  const isMobileApp = () => {
    return TNLifeBridge.isWebView;
  };

  const isDesktopApp = () => {
    return TNLifeBridge.isIFrame;
  };

  const sendMessage = <T, D = Record<string, never>>(type: LifeMiniAppMessageType, data: D) => {
    return TNLifeBridge.send<D, T>(type, data);
  };

  const lifeMiniAppInit = () => {
    return sendMessage<InitEventResult>(LifeMiniAppMessageType.LifeMiniAppInit, {});
  };

  const getLifeMiniAppAuthCode = () => {
    return sendMessage<AuthCodeEventResult | AuthCodeFailedEventResult>(LifeMiniAppMessageType.LifeMiniAppAuthCode, {});
  };

  const downloadFile = (file: DownloadFileData) => {
    return sendMessage<DownloadFileEventResult | DownloadFileFailedEventResult, DownloadFileData>(
      LifeMiniAppMessageType.LifeMiniAppDownloadFile,
      file,
    );
  };

  const pickFile = () => {
    return sendMessage<PickFileEventResult | PickFileFailedEventResult, { type: PickFileExtensionMode }>(
      LifeMiniAppMessageType.LifeMiniAppPickFile,
      { type: PickFileExtensionMode.AllExtensions },
    );
  };

  const pickFiles = (type: PickFileExtensionMode, max_files: number) => {
    return sendMessage<
      PickFilesEventResult | PickFilesFailedEventResult,
      { type: PickFileExtensionMode; max_files: number }
    >(LifeMiniAppMessageType.LifeMiniAppPickFiles, { type, max_files });
  };

  const openFile = (file: OpenFileData) => {
    return sendMessage<OpenFileEventResult | OpenFileFailedEventResult, OpenFileData>(
      LifeMiniAppMessageType.LifeMiniAppOpenFile,
      file,
    );
  };

  const saveToGallery = (image: SaveToGalleryData) => {
    return sendMessage<SaveToGalleryEventResult | OpenFileFailedEventResult, SaveToGalleryData>(
      LifeMiniAppMessageType.LifeMiniAppSaveToGallery,
      image,
    );
  };

  const takePicture = () => {
    return sendMessage<TakePictureEventResult | TakePictureFailedEventResult>(
      LifeMiniAppMessageType.LifeMiniAppTakePicture,
      {},
    );
  };

  const getAppLink = (payload: string) => {
    return sendMessage<GetAppLinkEventResult, GetAppLinkData>(LifeMiniAppMessageType.LifeMiniAppGetAppLink, {
      payload,
    });
  };

  const openExternalUrl = (payload: OpenExternalUrlData) => {
    return sendMessage<OpenExternalUrlResultData | OpenExternalUrlFailedEventResult, OpenExternalUrlData>(
      LifeMiniAppMessageType.LifeMiniAppOpenUrl,
      payload,
    );
  };

  const getClientVersion = () => {
    return sendMessage<GetClientVersionResult>(LifeMiniAppMessageType.LifeMiniAppGetClientVersion, {});
  };
  const openService = (url: string) => {
    return sendMessage<OpenServiceResultData | OpenServiceFailedEventResult, OpenServiceData>(
      LifeMiniAppMessageType.LifeMiniAppOpenService,
      { url },
    );
  };

  // Проверяет поддержку метода в TN Life
  const checkSupportMethod = (method: LifeMiniAppMessageType) => {
    return sendMessage<CheckSupportMethodEventResult | CheckSupportMethodFailedEventResult, CheckSupportMethodData>(
      LifeMiniAppMessageType.LifeMiniAppSupports,
      { method },
    ).then((resp) => {
      return resp.type === ResultEventType.LifeMiniAppSupportsResult && resp.data.result;
    });
  };

  // Показать splash-экран
  const showSplashScreen = () => {
    return sendMessage<ShowSplashResult>(LifeMiniAppMessageType.LifeMiniAppShowSplash, {});
  };

  // Скрыть splash-экран
  const hideSplashScreen = () => {
    return sendMessage<HideSplashResult>(LifeMiniAppMessageType.LifeMiniAppHideSplash, {});
  };

  return {
    isApp,
    isMobileApp,
    isDesktopApp,
    isBrowser,
    lifeMiniAppInit,
    getLifeMiniAppAuthCode,
    downloadFile,
    openFile,
    saveToGallery,
    pickFile,
    takePicture,
    pickFiles,
    getAppLink,
    openExternalUrl,
    getClientVersion,
    openService,
    checkSupportMethod,
    showSplashScreen,
    hideSplashScreen,
  };
};
