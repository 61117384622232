import { RouteType } from "@/router/route.type";
import { RouteRecordRaw } from "vue-router";
import { AclRole } from "@/shared/plugin/acl/acl.type";

export const subordinatesRequestsRoutes: RouteRecordRaw = {
  path: "/subordinates",
  name: RouteType.Subordinates,
  redirect: {
    name: RouteType.SubordinatesRequestsList,
  },
  component: () => import("@/views/subordinates/subordinates.vue"),
  meta: {
    access: [AclRole.Accountant, AclRole.ApprovingAgent, AclRole.Director, AclRole.Admin, AclRole.Support],
  },
  children: [
    {
      path: "",
      name: RouteType.SubordinatesRequestsList,
      component: () =>
        import("@/views/subordinates/subordinates-requests-list-page/subordinates-requests-list-page.vue"),
      meta: {
        title: "Заявки сотрудников",
      },
    },
  ],
};
