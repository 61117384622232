<template>
  <Auth>
    <Locale>
      <Layouts />
    </Locale>
  </Auth>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Layouts from "@/layouts/layouts.vue";
import Auth from "@/shared/ui/auth/auth.vue";
import Locale from "@/components/locale/locale.vue";

export default defineComponent({
  name: "App",
  components: {
    Auth,
    Layouts,
    Locale,
  },
});
</script>

<style lang="scss" src="./app.scss" />
