import { Ref, ref } from "vue";
import {
  Action,
  ActionWithFiveParameters,
  ActionWithFourParameters,
  ActionWithOneParameter,
  ActionWithThreeParameters,
  ActionWithTwoParameters,
} from "@/shared/types";

interface UseLoaderResult<T> {
  isLoading: Ref<boolean>;
  actionWithLoader: T;
}

export function useLoader<R>(action: Action<R>): UseLoaderResult<Action<R>>;
export function useLoader<R, P1>(action: ActionWithOneParameter<R, P1>): UseLoaderResult<ActionWithOneParameter<R, P1>>;
export function useLoader<R, P1, P2>(
  action: ActionWithTwoParameters<R, P1, P2>,
): UseLoaderResult<ActionWithTwoParameters<R, P1, P2>>;
export function useLoader<R, P1, P2, P3>(
  action: ActionWithThreeParameters<R, P1, P2, P3>,
): UseLoaderResult<ActionWithThreeParameters<R, P1, P2, P3>>;
export function useLoader<R, P1, P2, P3, P4>(
  action: ActionWithFourParameters<R, P1, P2, P3, P4>,
): UseLoaderResult<ActionWithFourParameters<R, P1, P2, P3, P4>>;
export function useLoader<R, P1, P2, P3, P4, P5>(
  action: ActionWithFiveParameters<R, P1, P2, P3, P4, P5>,
): UseLoaderResult<ActionWithFiveParameters<R, P1, P2, P3, P4, P5>>;

export function useLoader(
  action: (p1?: unknown, p2?: unknown, p3?: unknown, p4?: unknown, p5?: unknown) => Promise<unknown>,
): UseLoaderResult<unknown> {
  const isLoading = ref(false);

  const returnAction = async (p1?: unknown, p2?: unknown, p3?: unknown, p4?: unknown, p5?: unknown) => {
    isLoading.value = true;
    try {
      return await action(p1, p2, p3, p4, p5);
    } finally {
      isLoading.value = false;
    }
  };

  return {
    isLoading: isLoading,
    actionWithLoader: returnAction,
  };
}
