import { createApp } from "vue";
import App from "./app.vue";
import router from "./router";
import i18n from "./i18n";
import DeviceDetect from "./shared/plugin/device-detect/device-detect";
import { simpleAcl } from "./shared/plugin/acl/acl";
import { useTNLifeBridge } from "./shared/composables/use-tnlife-bridge/use-tnlife-bridge";
import Maska from "maska";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { AuthDataKey } from "@/store/modules/auth";
import { defineNavigation } from "@/shared/plugin/navigation/navigation";
import { useWidgetLayout } from "@/layouts/widget/composables/use-widget-layout";
import { VueQueryPlugin } from "vue-query";
import VueYandexMetrika from "vue3-yandex-metrika";
import { appConfig } from "@/config/config";

const { isApp, lifeMiniAppInit, getClientVersion } = useTNLifeBridge();

const { showWidgetSkeleton, widgetURL } = useWidgetLayout();
if (location.pathname === widgetURL) {
  showWidgetSkeleton();
}

dayjs.extend(utc);
dayjs.extend(timezone);

function initApp() {
  const finApp = createApp(App);
  finApp.use(router);
  finApp.use(defineNavigation(router));
  finApp.use(i18n);
  finApp.use(DeviceDetect);
  finApp.use(simpleAcl);
  finApp.use(Maska);
  finApp.use(VueQueryPlugin);
  finApp.mount("#app");

  if (appConfig.VUE_APP_YANDEX_METRIKA_ID) {
    finApp.use(VueYandexMetrika, {
      id: appConfig.VUE_APP_YANDEX_METRIKA_ID,
      router,
      env: process.env?.NODE_ENV || "development",
      debug: false,
      options: {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
      },
    });
  }
}

if (isApp()) {
  void lifeMiniAppInit().then(async ({ data: { result, payload } }) => {
    const getClientVersionRes = await getClientVersion();
    if (getClientVersionRes.data.platform === "web") {
      if (window.localStorage.getItem(AuthDataKey.Token)) {
        window.localStorage.removeItem(AuthDataKey.Token);
      }
      if (window.localStorage.getItem(AuthDataKey.RefreshToken)) {
        window.localStorage.removeItem(AuthDataKey.RefreshToken);
      }
      if (window.localStorage.getItem(AuthDataKey.ExpiresAt)) {
        window.localStorage.removeItem(AuthDataKey.ExpiresAt);
      }
    }

    const decodePayload = decodeURIComponent(payload || "");

    if (decodePayload && decodePayload !== window.location.href) {
      let redirectUrl;

      try {
        const url = new URL(decodePayload);
        redirectUrl = `${url.pathname}${url.search}${url.hash}`;
      } catch {
        redirectUrl = decodePayload;
      }

      window.localStorage.setItem(AuthDataKey.RedirectRouterPathAfterAuth, redirectUrl);
    } else {
      window.localStorage.removeItem(AuthDataKey.RedirectRouterPathAfterAuth);
    }
    if (result) {
      initApp();
    }
  });
} else {
  initApp();
}
