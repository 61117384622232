import widgetStyle from "./widget-layout.module.scss";

interface SkeletonElementOptions {
  width?: string;
  height?: string;
  class?: string;
  borderRadius?: string;
}

const createDiv = (params: { id?: string; class?: string | string[] }) => {
  const div = document.createElement("div");
  if (params.id) {
    div.id = params.id;
  }
  if (params.class) {
    if (typeof params.class === "string") {
      div.classList.add(params.class);
    }
    if (Array.isArray(params.class)) {
      params.class.forEach((className) => div.classList.add(className));
    }
  }
  return div;
};

const defaultSkeletonElementOptions = {
  width: "150px",
  height: "32px",
  borderRadius: "12px",
};

const createSkeletonElement = (options: SkeletonElementOptions) => {
  const classList = [widgetStyle.placeholder_skeleton, ...(options.class ? [options.class] : [])];
  const el = createDiv({ class: classList });
  el.style.height = options.height || defaultSkeletonElementOptions.height;
  el.style.width = options.width || defaultSkeletonElementOptions.width;
  el.style.borderRadius = options.borderRadius || defaultSkeletonElementOptions.borderRadius;

  return el;
};

export function useWidgetLayout() {
  const widgetURL = "/widget";
  const rootSkeletonID = widgetStyle["skeleton__root"];
  const rootSkeleton = createDiv({ id: rootSkeletonID });

  const createWidgetBtnBlock = () => {
    const btnWrapper = createDiv({ class: widgetStyle.widgetSkeleton__btn });
    const btn = createSkeletonElement({ width: "140px", height: "30px" });
    btnWrapper.append(btn);
    return btnWrapper;
  };

  const createWidgetListItem = () => {
    const item = createDiv({ class: widgetStyle.widgetSkeleton__item });

    const title = createDiv({ class: widgetStyle.widgetSkeleton__title });
    title.append(createSkeletonElement({ width: "190px", height: "30px" }));

    const info = createDiv({ class: widgetStyle.widgetSkeleton__info });
    info.append(createSkeletonElement({ width: "90px", height: "20px" }));
    info.append(createSkeletonElement({ width: "20px", height: "20px", borderRadius: "50%" }));
    info.append(createSkeletonElement({ width: "80px", height: "20px" }));

    item.append(title);
    item.append(info);
    return item;
  };

  const createWidgetListBlock = () => {
    const list = createDiv({ class: widgetStyle.widgetSkeleton__list });

    list.append(createWidgetListItem());
    list.append(createWidgetListItem());

    return list;
  };

  const createWidgetCreateBtnListBlock = () => {
    const btnList = createDiv({ class: widgetStyle.widgetSkeleton__createBtn });

    btnList.append(createSkeletonElement({ width: "calc(50% - 8px)", height: "calc(50% - 8px)" }));
    btnList.append(createSkeletonElement({ width: "calc(50% - 8px)", height: "calc(50% - 8px)" }));
    btnList.append(createSkeletonElement({ width: "calc(50% - 8px)", height: "calc(50% - 8px)" }));
    btnList.append(createSkeletonElement({ width: "calc(50% - 8px)", height: "calc(50% - 8px)" }));

    return btnList;
  };

  rootSkeleton.append(createWidgetBtnBlock());
  rootSkeleton.append(createWidgetListBlock());
  rootSkeleton.append(createWidgetCreateBtnListBlock());

  const showWidgetSkeleton = () => {
    document.body.append(rootSkeleton);
  };

  const hideWidgetSkeleton = () => {
    document.getElementById(rootSkeletonID)?.remove();
  };

  return {
    widgetURL,
    showWidgetSkeleton,
    hideWidgetSkeleton,
  };
}
