import { AbsenceReason } from "@/store/modules/absence/absence.types";

export type SetRule = <T>(rule: AclRules | AclRole | AclPrivileges, callback: T) => void;

export enum AclRole {
  User = "user",
  ApprovingAgent = "approving_agent",
  Accountant = "accountant",
  Director = "director",
  Admin = "admin",
  Support = "support",
}

export enum AclPrivileges {
  AdvanceReportsCreate = "advance_reports.create",
  AdvanceReportsList = "advance_reports.list",
  AdvanceReportsView = "advance_reports.view",
  BusinessTripsCreate = "business_trips.create",
  BusinessTripsList = "business_trips.list",
  BusinessTripsView = "business_trips.view",
  AbsenceSelfApprovalAbsenteeism = "absence.self_approval.absenteeism",
  AbsenceSelfApprovalBusinessTrip = "absence.self_approval.business_trip",
  AbsenceSelfApprovalDayOff = "absence.self_approval.day_off",
  AbsenceSelfApprovalDistantWork = "absence.self_approval.distant_work",
  AbsenceSelfApprovalIllness = "absence.self_approval.illness",
  AbsenceSelfApprovalOwnExpenseVacation = "absence.self_approval.own_expense_vacation",
  AbsenceSelfApprovalTraining = "absence.self_approval.training",
  AbsenceSelfApprovalVacation = "absence.self_approval.vacation",
  AdvanceReportsSelfApproval = "advance_reports.self_approval",
  RequestsCanApproval = "requests.can_approve",
}

export enum AclRules {
  BusinessTripsEdit = "business_trips.edit",
  BusinessTripsChangeStatus = "business_trips.change_status",
  BusinessTripCreateAdvanceReport = "business_trips.create_advance_report",
  AbsenceEditAfterAccepted = "absence.edit_after_accepted",
  AbsenceRevokeAfterAccepted = "absence.revoke_after_accepted",
  AbsenceCancelAfterAccepted = "absence.cancel_after_accepted",
  AbsenceChangeDate = "absence.change_date",
  AbsenceRevoke = "absence.revoke",
  AbsenceEditReplacementManager = "absence.edit_replacement_manager",
  AbsenceEditApprovingManager = "absence.edit_approving_manager",
  AbsenceChangeAbsenceReason = "absence.change_absence_reason",
  AbsenceSetReplacementManager = "absence.set_replacement_manager",
  AbsenceBusinessTripsDigitalSign = "absence_business_trips.digital_sign",
  AbsenceBusinessTripOrderView = "absence_business_trips.order.view",
  AbsenceVacationOrderSign = "absence_vacation.order_sign",
  AbsenceVacationOrderView = "absence_vacation.order_view",
  AbsenceVacationRequestSign = "absence_vacation.request_sign",
  AbsenceVacationRequestView = "absence_vacation.request_view",
  AbsenceBusinessTripsPhysicalSign = "absence_business_trips.physical_sign",
  AdvanceReportsEdit = "advance_reports.edit",
  AdvanceReportsApproveAccounter = "advance_reports.approve_accounter",
  AdvanceReportsApproveManager = "advance_reports.approve_manager",
  AdvanceReportsImprove = "advance_reports.improve",
  AdvanceReportsRevoke = "advance_reports.revoke",
  AdvanceReportsViewSign = "advance_reports.view_sign",
  AdvanceReportsDigitalSign = "advance_reports.digital_sign",
  AdvanceReportsPhysicalSign = "advance_reports.physical_sign",
  AdvanceReportsEditApprovingManager = "advance_reports.edit_approving_manager",
  ShowCostPlan = "show_cost_plan",
  SubReportEdit = "sub_report.edit",
  SubReportApprove = "sub_report.approve",
  SubReportImprove = "sub_report.improve",
  SubReportRevoke = "sub_report.revoke",
  SubReportCancellation = "sub_report.cancellation",
  CardOrderReleaseAgreementManager = "card_order.release.agreement_manager",
  CardOrderBlockCardApproveAccounter = "card_order.block_card.approve_accounter",
  CardOrderChangeLimitAgreementManager = "card_order.change_limit.agreement_manager",
  CardOrderChangeLimitApproveAccounter = "card_order.change_limit.approve_accounter",
  CardOrderRevoke = "card_order.revoke",
}

export type AbsenceSelfApprovalPermissionsList = {
  readonly [key in AbsenceReason]?: string;
};
