import { NavigationHookAfter } from "vue-router";
import { useLocationHistoryActions } from "@/store/modules/location-history";

const { updateLocationsHistoryState } = useLocationHistoryActions();

export const locationsHistoryHook: NavigationHookAfter = ({ fullPath, meta }) => {
  if (!meta.excludeFromLocationsHistory) {
    updateLocationsHistoryState(fullPath);
  }
};
