import { ElMessageBox, ElMessageBoxOptions, MessageBoxData } from "element-plus";
import "./message.scss";
import { h, VNode } from "vue";
import { FIN_APP_SUPPORT_EMAIL } from "@/shared/constants";

export const showMessageBox = (message: string | VNode, options: ElMessageBoxOptions = {}): Promise<MessageBoxData> => {
  const messageOptions: ElMessageBoxOptions = {
    message,
    customClass: "tn_message_box",
    confirmButtonText: "Ok",
    type: "success",
    confirmButtonClass: "tn_message_box__button tn_message_box__button--confirm",
    closeOnClickModal: false,
    closeOnPressEscape: false,
  };

  return ElMessageBox({ ...messageOptions, ...options });
};

export const showConfirmMessage = (
  message: string | VNode,
  options: ElMessageBoxOptions = {},
): Promise<MessageBoxData> => {
  const messageOptions: ElMessageBoxOptions = {
    message,
    customClass: "tn_message_box tn_message_box-confirm",
    type: "warning",
    confirmButtonText: "Ok",
    confirmButtonClass: "tn_message_box__button tn_message_box__button--confirm",
    showCancelButton: true,
    cancelButtonText: "Отмена",
    cancelButtonClass: "tn_message_box__button tn_message_box__button--cancel",
    closeOnClickModal: false,
    closeOnPressEscape: false,
  };

  return ElMessageBox({ ...messageOptions, ...options });
};

export const showErrorMessage = (
  message: string | VNode,
  options: ElMessageBoxOptions = {},
): Promise<MessageBoxData> => {
  const messageOptions: ElMessageBoxOptions = {
    message,
    customClass: "tn_message_box tn_message_box-confirm",
    type: "error",
    confirmButtonText: "Ok",
    confirmButtonClass: "tn_message_box__button tn_message_box__button--confirm",
    cancelButtonText: "Отмена",
    cancelButtonClass: "tn_message_box__button tn_message_box__button--cancel",
    closeOnClickModal: false,
    closeOnPressEscape: false,
  };

  return ElMessageBox({ ...messageOptions, ...options });
};

export const showBlockingMessageWithSupport = (message: string) => {
  const errorMessage = (message: string) => {
    return (
      <div>
        <p>{message}</p>
        <small>
          Обратитесь в поддержку ресурса: <a href={`mailto:${FIN_APP_SUPPORT_EMAIL}`}>{FIN_APP_SUPPORT_EMAIL}</a>
        </small>
      </div>
    );
  };

  return showErrorMessage(h(errorMessage(message)), {
    confirmButtonText: "На главную",
    closeOnClickModal: false,
    closeOnPressEscape: false,
  });
};
